<template>
  <div class="footerbox">
    <div class="content_box">
      <div class=" link_box">
        <div class="kk"></div>
        <span class="sp1" >友情链接：</span>
        <span class="sp" @click="goto(1)">免费加速器</span>
        <span class="sp" @click="goto(2)">AK加速器</span>
        <span class="sp" @click="goto(3)">野豹加速器</span>
        <!-- <span class="sp" @click="goto(4)">游侠网</span> -->
        <span class="sp" @click="goto(5)">其乐</span>
        <span class="sp" @click="goto(6)">绝地求生</span>
        <!-- <span class="sp" @click="goto(7)">3DMGAME</span> -->
      </div>
      <div class="user_reg_info">
            <div class="kkk"></div>
           <span class="sp_s" @click="goto(100)" >关于我们</span>
           <div class="kkk1"></div>
           <span class="sp_s" @click="goto(0)" >用户协议</span>
           <div class="kkk2"></div>
           <span class="sp_s" @click="goto(10)" >关于共享游戏</span>
           <div class="kkk4"></div>
           <span class="sp_s" @click="goto(1000)" >会员服务协议</span>
           <div class="kkk3"></div>
           <span class="sp_ss"  >联系我们：028-83371341</span>
      </div>
      <div class="d_line"></div>
      <div class="xx_box">
        <span>四川精诚智达网络技术有限公司 经营性许可证:川B2-20190308  &nbsp;&nbsp; 备案号:</span><a class="a_link" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备18019820号-7</a><span> &nbsp;&nbsp; 川网文 [2019] 1672-101号   </span>
        <div class="gongan"></div>
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51130202000433" class="gongan_text">川公网安备 51130202000433号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footernav',
  data() {
    return {
       
    };
  },
 
  methods:{
    goto(index){
         if(index==0){
           const routeData = this.$router.resolve({ path: '/UserAgreement'})
           window.open(routeData.href, '_blank')


             //this.$router.push('/UserAgreement')
         }
         if(index==10){
           const routeData = this.$router.resolve({ path: '/GXUserAgreement'})
           window.open(routeData.href, '_blank')


             //this.$router.push('/UserAgreement')
         }
         if(index==100){
          //  const routeData = this.$router.resolve({ path: '/aboutUs'})
          //  window.open(routeData.href, '_blank')


            this.$router.push('/aboutUs')
            // this.$store.state.dialogVisible=true;
         }
         if(index==1000){
          //  const routeData = this.$router.resolve({ path: '/aboutUs'})
          //  window.open(routeData.href, '_blank')


            
            const routeData = this.$router.resolve({ path: '/HYUserAgreement'})
           window.open(routeData.href, '_blank')
            // this.$store.state.dialogVisible=true;
         }
         if(index==1){
           window.open("https://www.akspeedy.com", "_blank");
         }
         if(index==2){
           window.open("https://www.akspeedy.com", "_blank");
         }
         if(index==3){
           window.open("https://www.yebaojiasu.com/", "_blank");
         }
         if(index==4){
           window.open("https://www.ali213.net/", "_blank");
         }
         if(index==5){
           window.open("https://keylol.com/", "_blank");
         }
         if(index==6){
           window.open("https://cn.pubg.com/", "_blank");
         }
         if(index==7){
           window.open("https://www.3dmgame.com/", "_blank");
         }
    },
  },
}
</script>
<style scoped lang='less'>
.footerbox{
    width: 100%;
    height: 120px;
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 910px;
    .content_box{
      width: 1250px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .link_box{
        width: 100%;
        height: 50px;
        //border: 1px solid #fff;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        .kk{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
        }
        .sp1{
          margin-left: 5px;
          color: #DCDCDC;
          font-size: 16px;
        }
        .sp{
          margin-left: 30px;
          color: #999999;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .user_reg_info{
        width: 100%;
        height: 50px;
        
        display: flex;
        align-items: center;
        .kkk{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
        }
        .kkk1{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
          margin-left: 30px;
        }
        .kkk2{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
          margin-left: 30px;
        }
        .kkk3{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
          margin-left: 30px;
        }
        .kkk4{
          width: 4px;
          height: 16px;
          background-color: #5DFE97 ;
          margin-left: 30px;
        }
        .sp_s{
          margin-left: 5px;
          color:#DCDCDC;
          font-size: 16px;
        }
        .sp_s:hover{
          cursor: pointer;
          color:#fff;
        }
        .sp_ss{
          margin-left: 5px;
          color:#DCDCDC;
          font-size: 16px;
        }
      }
      .d_line{
        margin-top:10px ;
        width: 100%;
        height: 1px;
        border-top: 1px dotted #fff;
      }
      .xx_box{
        margin-top:10px ;
        width: 100%;
        height: 50px;
        
        display: flex;
        span{
          color: #767676;
          font-size: 14px;
          text-align: start;
        }
        .a_link{
          color: #767676;
          font-size: 14px;
        }
        .a_link:hover{
          color: #fff;
        }
        .gongan{
          width: 20px;
          height: 20px;
            background: url(~@/assets/images/new/gongan.png)  ;
           margin-left: 10px;
        }
        .gongan_text{
          margin-left: 10px;
          color: #767676;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
}

</style>
