<template>
  <div class="topbox">
    <div class="content_box">
      <div class="logo"></div>
      <div
        class="nav_box"
        :class="{
          nav_box_1: nav_index == 1,
          nav_box_2: nav_index == 2,
          nav_box_3: nav_index == 3,
        }"
      >
        <div
          :class="{ s_color_1: nav_index == 1, s_color_2: nav_index != 1 }"
          class="nav_item"
          @click="nav_goto(1)"
        >
          <span>共享游戏</span>
        </div>
        <div
          :class="{ s_color_1: nav_index == 2, s_color_2: nav_index != 2 }"
          class="nav_item"
          @click="nav_goto(2)"
        >
          <span>独享游戏</span>
        </div>
        <div
          :class="{ s_color_1: nav_index == 3, s_color_2: nav_index != 3 }"
          class="nav_item"
          @click="nav_goto(3)"
        >
          <span>绝地求生G币</span>
        </div>
        <div
          :class="{ s_color_1: nav_index == 4, s_color_2: nav_index != 4 }"
          class="nav_item"
          @click="nav_goto(4)"
        >
          <span>客户端</span>
        </div>
      </div>
      <div class="search_box">
        <el-input
          v-model="searchtext"
          placeholder="搜索游戏/商品名称"
          @keyup.enter.native="search"
          class="search_input"
        ></el-input>
        <div class="search_btn" @click="search"></div>
      </div>
      <div class="download" @click="downloadFile"></div>
      <div class="tx_box" @mouseover="mouseEnter">
        <div class="pop" v-show="myinfoshow" @mouseleave.stop="mouseOut">
          <div class="sj"></div>
          <div class="tx_p"></div>
          <div class="order_p_box">
            <div class="order_ico"></div>
            <span class="order_p" @click="gotoOrder">我的订单</span>
          </div>
          <div class="exit_p_box">
            <div class="exit_ico"></div>
            <span class="exit_p" @click="dropout">退出登陆</span>
          </div>
        </div>
      </div>
      <div class="login_btn" @click="login">
        <span>{{ this.$store.state.login_title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfobyTK, addDown } from "@/service/api";
export default {
  name: "top",
  data() {
    return {
      nav_index: 1,
      searchtext: "",
      myinfoshow: false,
    };
  },
  components: {},
  created() {
    //渠道
    if (this.$route.query.ch) {
      //渠道
      sessionStorage.setItem("channel_code", this.$route.query.ch);
      this.$store.state.channel_code = this.$route.query.ch;
    } else {
      if (sessionStorage.getItem("channel_code")) {
        //渠道
        this.$store.state.channel_code = sessionStorage.getItem("channel_code");
      }
    }

    let t_token = localStorage.getItem("token");
    // console.log(t_token)
    // console.log('1111')

    if (t_token != null) {
      if (t_token.substring(0, 3) == "gw:") {
        t_token = t_token.slice(3);
      }
      this.$store.state.global_tk = t_token;
      if (this.$store.state.global_tk != "") {
        this.$store.state.global_tk_url =
          "?token=" + this.$store.state.global_tk;
      }
      this.$store.state.global_url =
        "https://gg-web.wanjiayizhan.com/Store" +
        this.$store.state.global_tk_url;
    } else {
      this.$store.state.login_title = "你好，请【登录】";
    }
  },
  async mounted() {
    //挂载方法 给那边用
    window["showLogin"] = () => {
      this.showLogin();
    };
    window.addEventListener("message", function (msg) {
      if (msg.origin === "https://gg-web.wanjiayizhan.com") {
        if (msg.data === "showLogin") {
          //你的登录方法
          showLogin();
        }
        if (msg.data.indexOf("openUrl:") > -1) {
          //打开新页面
          window.open(msg.data.split("openUrl:")[1]);
        }
      }
    });
    //根据token获取用户信息
    if (this.$store.state.global_tk != "") {
      const data = await getUserInfobyTK({});
      if (data.code === 200) {
        // console.log(data.data.phone)
        this.$store.state.phone = data.data.phone;
        this.$store.state.login_title = this.$store.state.phone + "【退出】";
      }
    } else {
      this.$store.state.login_title = "你好，请【登录】";
    }
    const paramValue = this.$route.query.nav; // 获取名为paramName的参数值
    if (paramValue != undefined){
       console.log("参数:"+paramValue);
       this.nav_goto(paramValue)
    }
  },
  methods: {
    mouseEnter() {
      let t_token = localStorage.getItem("token");
      //console.log(t_token)
      if (t_token != null) {
        this.myinfoshow = true;
      } else {
      }
    },
    mouseOut() {
      this.myinfoshow = false;
    },

    showLogin() {
      this.$store.state.isloginshow = true;
    },
    dropout() {
      localStorage.removeItem("token");
      this.$store.state.global_tk = "";
      this.$store.state.global_tk_url = "";
      this.$store.state.phone = "";
      this.$store.state.login_title = "你好，请【登录】";
      this.nav_goto(this.nav_index);
    },
    login() {
      if (this.$store.state.phone == "" || this.$store.state.phone == null) {
        this.$store.state.isloginshow = true;
      } else {
        this.dropout();
      }
    },
    nav_goto(index) {
      this.$store.state.current_nav_index = index;
      this.nav_index = index;
      if (index == 1) {
        this.$router.push("/");
        if (this.$store.state.global_tk != "") {
          this.$store.state.global_tk_url =
            "?token=" + this.$store.state.global_tk;
        }
        this.$store.state.global_url =
          "https://gg-web.wanjiayizhan.com/Store" +
          this.$store.state.global_tk_url;
      }
      if (index == 2) {
        this.$router.push("/");
        if (this.$store.state.global_tk != "") {
          this.$store.state.global_tk_url =
            "?token=" + this.$store.state.global_tk;
        }
        this.$store.state.global_url =
          "https://gg-web.wanjiayizhan.com/Wjyz" +
          this.$store.state.global_tk_url;
        // console.log("https://gg-web.wanjiayizhan.com/Wjyz"+this.$store.state.global_tk_url)
      }
      if (index == 3) {
        console.log("33333")
        this.$router.push("/");
        if (this.$store.state.global_tk != "") {
          this.$store.state.global_tk_url =
            "&token=" + this.$store.state.global_tk;
        }
        this.$store.state.global_url =
          "https://gg-web.wanjiayizhan.com/AllGame?from=wjyz&index=4" +
          this.$store.state.global_tk_url;
      }
      if (index == 4) {
        if (this.$router.currentRoute.path == "/129") {
          this.$router.push("/download_129");
        } else if (this.$router.currentRoute.path == "/130") {
          this.$router.push("/download_130");
        } else {
          this.$router.push("/download");
        }
      }
    },
    gotoOrder() {
      this.$router.push("/");
      if (this.$store.state.global_tk != "") {
        this.$store.state.global_tk_url =
          "?token=" + this.$store.state.global_tk;
      }
      this.$store.state.global_url =
        "https://gg-web.wanjiayizhan.com/Personal/1" +
        this.$store.state.global_tk_url;
      //  console.log("https://gg-web.wanjiayizhan.com/Personal/1"+this.$store.state.global_tk_url)
    },
    search() {
      if (this.$store.state.global_tk != "") {
        this.$store.state.global_tk_url =
          "?token=" + this.$store.state.global_tk;
      }
      this.$store.state.global_url =
        "https://gg-web.wanjiayizhan.com/Search/" +
        this.searchtext +
        this.$store.state.global_tk_url;
    },
    downFile() {
      console.log(this.$router.currentRoute.path);
      let obj = {};
      obj = {
        url: this.$store.state.downfile.url,
        name: this.$store.state.downfile.filename,
      };
      return obj;
    },
    downloadFile() {
      if (this.$store.state.channel_code == "") {
        //非渠道
        let url = this.$store.state.downfile.url; //主站
        //  let url = this.$store.state.downfile_main_bd.url;//百度推广的
        // let url = this.$store.state.downfile_main_360.url;//360的
        //   if (this.$router.currentRoute.path == "/129") {
        //   }
        //   if (this.$router.currentRoute.path == "/130") {
        //   }
        // console.log(this.$router.currentRoute.path)
        // console.log(window.location.href)

        const fileUrl = url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "this.$store.state.downfile.filename");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // this.addDownfun(0);//百度
        // this.addDownfun(1);//360
      } else {
        //渠道
        console.log(this.$store.state.channel_code);
        let d_index = this.$store.state.downfile_channel_list.findIndex(
          (item) => item.code == this.$store.state.channel_code
        );
        let url = this.$store.state.downfile.url; //主站
        if (d_index != -1) {
          //没有查到 默认主站
          url = this.$store.state.downfile_channel_list[d_index].url;
        }
        const fileUrl = url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "this.$store.state.downfile.filename");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.addDownfun_channel(
          this.$store.state.downfile_channel_list[d_index].down_code
        ); //打点
      }
    },
    async addDownfun_channel(val) {
      const res = await addDown(val);
      if (res.code == 200) {
      } else {
      }
    },

    async addDownfun(val) {
      let gameName = "";
      if (val == 0) {
        gameName = "百度_推广首页";
      } else {
        gameName = "360_推广首页";
      }
      let p = {
        type: 2,
        gameName: gameName,
      };
      const res = await addDown(p);

      if (res.code == 200) {
      } else {
      }
    },
  },
};
</script>
<style scoped lang="less">
.topbox {
  display: flex;
  width: 100%;
  height: 80px;
  background-color: #000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .content_box {
    width: 1250px;
    height: 100%;
    display: flex;
    align-items: center;
    .logo {
      width: 138px;
      height: 32px;
      background: url(~@/assets/images/new/top/logo.png);
    }
    .nav_box {
      margin-left: 10px;
      width: 512px;
      height: 80px;
      background: url(~@/assets/images/new/top/nav_bg.png);
      display: flex;
      align-items: center;
    }
    .nav_box_1 {
      background-position: -384px 0px;
    }
    .nav_box_2 {
      background-position: -256px 0px;
    }
    .nav_box_3 {
      background-position: -128px 0px;
    }
    .nav_box_4 {
      background-position: -0px 0px;
    }
    .s_color_1 {
      color: #5dfe97;
    }
    .s_color_2 {
      color: #fff;
    }
    .nav_item {
      width: 128px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      span {
        font-size: 16px;
      }
    }
    .nav_item:hover {
      color: #5dfe97;
    }
    .search_box {
      // border: 1px solid #ffffff;
      width: 253px;
      height: 36px;
      margin-left: 10px;
      background-color: #262626;
      display: flex;
      align-items: center;
      border-radius: 2px;
      background: url(~@/assets/images/new/top/search_box.png);
      .search_input {
        padding-left: 10px;
      }
      .search_btn {
        width: 14px;
        height: 15px;
        background: url(~@/assets/images/new/top/search_btn.png);
        cursor: pointer;
        margin-left: 0px;
      }
      .search_btn:hover {
        background-position: -14px 0px;
      }
    }
    .search_box:hover {
      background-position: -253px 0px;
    }
    .download {
      margin-left: 20px;
      width: 108px;
      height: 36px;
      background: url(~@/assets/images/new/top/download.png);
      cursor: pointer;
    }
    .download:hover {
      background-position: -108px 0px;
    }
    .tx_box {
      margin-left: 20px;
      width: 32px;
      height: 32px;
      background: url(~@/assets/images/new/top/tx.png);
    }
    .pop {
      z-index: 999;
      position: relative;
      width: 150px;
      height: 120px;
      background-color: #444;
      top: 45px;
      right: 60px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tx_p {
        margin-top: 10px;
        width: 32px;
        height: 32px;
        background: url(~@/assets/images/new/top/tx.png);
      }
      .order_p_box {
        display: flex;
        margin-top: 10px;
        .order_ico {
          margin-left: -10px;
          width: 16px;
          height: 17px;
          background: url(~@/assets/images/new/top/myorder.png);
        }
        .order_p {
          color: #aaaaaa;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .order_p_box:hover {
        cursor: pointer;
        .order_ico {
          margin-left: -10px;
          width: 16px;
          height: 17px;
          background: url(~@/assets/images/new/top/myorder.png);
          background-position: 16px 0px;
        }
        .order_p {
          color: #87f99d;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .exit_p_box {
        display: flex;

        margin-top: 10px;
        .exit_ico {
          margin-left: -10px;
          width: 16px;
          height: 17px;
          background: url(~@/assets/images/new/top/exit.png);
        }
        .exit_p {
          color: #aaaaaa;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .exit_p_box:hover {
        cursor: pointer;
        .exit_ico {
          margin-left: -10px;
          width: 16px;
          height: 17px;
          background: url(~@/assets/images/new/top/exit.png);
          background-position: 16px 0px;
        }
        .exit_p {
          color: #87f99d;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      .sj {
        width: 0;
        height: 0;
        top: -20px;
        left: 65px;
        border: 10px solid transparent;
        border-bottom-color: #444;
        position: absolute;
      }
    }

    .tx_box:hover {
      cursor: pointer;
    }
    .login_btn {
      cursor: pointer;
      margin-left: 10px;
      span {
        font-size: 12px;
        color: #a8a8a8;
      }
    }
  }
}
/deep/.el-input__inner {
  height: 30px;
  width: 220px;
  background: #262626;
  border: none;
  font-size: 14px;
  // font-family: PingFangSC-Regular, PingFang SC;
  color: #d4d4d4;
}
</style>
